const styles = {
  cardContent: {
    padding: {
      xs: "40px 0 36px 0 !important",
      sm: "40px 0 36px 0 !important",
    },
  },
  followOnChip: {
    marginX: "24px",
    backgroundColor: "#EDF5F8",
    border: "solid 1px #4AA5C2",
  },
  chipText: {
    color: "#515151",
    fontSize: "12px",
    fontWeight: "400",
  },
};

export default styles;
