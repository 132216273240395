import { Dispatch } from "redux";

import { IFollowOnSwitch } from "../reducers/followOnSwitch";

export const GET_FO_PROFILE = "GET_FO_PROFILE";
export const GET_STARTUP_PROFILE = "GET_STARTUP_PROFILE";

export const getFOProfile =
  (payload: IFollowOnSwitch) => (dispatch: Dispatch) => {
    return dispatch({
      type: GET_FO_PROFILE,
      payload,
    });
  };

export const getStartupProfile =
  (payload: IFollowOnSwitch) => (dispatch: Dispatch) => {
    return dispatch({
      type: GET_STARTUP_PROFILE,
      payload,
    });
  };
