/* eslint-disable react-hooks/exhaustive-deps */
import { UploadFile } from "@mui/icons-material";
import { Autocomplete, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { TextInput } from "../../../../../../components/Inputs";
import { useLoading } from "../../../../../../hooks/useLoading";
import { useSelector } from "../../../../../../redux/typedHooks";
import {
  deleteFileStartup,
  getAttachedFilesStartup,
} from "../../../../../../services/startup";
import { IFileObject } from "../../../../../../utils/types/common";
import { ERolesCode } from "../../../../../routes/role-catalog";
import mainStyles from "../../../../styles";
import Layout from "../../Layout/Layout";
import { RESPONSIVE_BUTTON } from "../../types";

import ButtonAddFile from "./ButtonAddFile/ButtonAddFile";
import { FileCard } from "./FileCard/FileCard";
import {
  EFileTypes,
  ITEM_VALUE_STARTUP,
  IValue,
  ROLES_TO_DELETE,
  ROLES_TO_DOWNLOAD,
  ROLES_TO_HIDE_DATA,
  VALID_TYPE_FILE_TO_UPLOAD_WITH_USER,
} from "./validations";

const AttachedFiles = () => {
  const { id } = useParams() as { id: string };
  const isMobile = useMediaQuery(RESPONSIVE_BUTTON);
  const [Files, setFiles] = useState<IFileObject[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [usersLiked, setUsersLiked] = useState<IValue[]>([]);
  const [userSelected, setUserSelected] = useState<IValue | null>(
    ITEM_VALUE_STARTUP,
  );
  const [userSelectedLabel, setUserSelectedLabel] = useState<string>("");
  const { handleClose, handleOpen } = useLoading();
  const { loggedUser } = useSelector((store) => store.loggedUser);
  const { profileId, profile } = useSelector(
    (store) => store.followOnSwitchReducer,
  );

  const isRoleToDelete = ROLES_TO_DELETE.includes(loggedUser.user.role.code);
  const isRoleToDownload = ROLES_TO_DOWNLOAD.includes(
    loggedUser.user.role.code,
  );

  const isRoleToHideData = ROLES_TO_HIDE_DATA.includes(
    loggedUser.user.role.code,
  );
  const existFiles = Files.length;

  useEffect(() => {
    getAttachedFilesStartup(profile === 1 ? profileId : id, loggedUser.user._id)
      .then(({ data }) => {
        const actualData = data.payload.info;

        let files: IFileObject[] = [];
        if (actualData?.dueDiligence) {
          files.push({
            ...actualData.dueDiligence,
            fileType: EFileTypes.dueDiligence,
          });
        }
        if (actualData?.safe) {
          files.push({ ...actualData.safe, fileType: EFileTypes.safe });
        }

        if (actualData?.depositStartup) {
          files.push({
            ...actualData.depositStartup,
            fileType: EFileTypes.depositStartup,
          });
        }

        if (actualData?.sisa) {
          if (loggedUser.user.role.code === ERolesCode.MEMBER) {
            const array = (actualData?.sisa as Array<any>).flatMap(
              (sisa: any) =>
                sisa.user._id === loggedUser.user._id
                  ? [{ ...sisa, fileType: EFileTypes.sisa }]
                  : [],
            );
            files = [...files, ...array];
          } else {
            const array = (actualData?.sisa as Array<any>).map((sisa: any) => ({
              ...sisa,
              fileType: EFileTypes.sisa,
            }));
            files = [...files, ...array];
          }
        }
        if (actualData?.comprobantes) {
          if (loggedUser.user.role.code === ERolesCode.MEMBER) {
            const array = (actualData?.comprobantes as Array<any>).flatMap(
              (comprobantes: any) =>
                comprobantes.user._id === loggedUser.user._id
                  ? [{ ...comprobantes, fileType: EFileTypes.depositSlip }]
                  : [],
            );
            files = [...files, ...array];
          } else {
            const array = (actualData?.comprobantes as Array<any>).map(
              (comprobantes: any) => ({
                ...comprobantes,
                fileType: EFileTypes.depositSlip,
              }),
            );
            files = [...files, ...array];
          }
        }

        const users = actualData?.usersWantToInvest.map((object: any) => ({
          name: `${object.user.firstName} ${object.user.lastName}`,
          value: object.user._id,
        }));

        setFiles(files);
        setUsersLiked([ITEM_VALUE_STARTUP, ...users]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, reload, profileId, profile]);

  async function handleDeleteFile(fileId: string, fileType: EFileTypes) {
    handleOpen();
    await deleteFileStartup(id, fileId, fileType);
    const copyFile = [...Files];
    const newFiles = copyFile.filter((i) => i.file._id !== fileId);
    setFiles(newFiles);
    handleClose();
  }

  function filterFiles(data: IFileObject[], user: IValue | null) {
    return data.filter((item) => {
      return (
        (VALID_TYPE_FILE_TO_UPLOAD_WITH_USER.includes(item.fileType) &&
          item.user?._id === user?.value) ||
        (user?.value === "Startup" &&
          !VALID_TYPE_FILE_TO_UPLOAD_WITH_USER.includes(item.fileType)) ||
        !user ||
        isRoleToHideData
      );
    });
  }

  return (
    <Layout>
      <Layout.Button>
        {!isRoleToHideData && !isMobile && (
          <ButtonAddFile
            id={id}
            xs={mainStyles.actionsButtons}
            setReload={() => setReload(!reload)}
            users={usersLiked}
          >
            <Typography fontWeight="500" fontSize="14px">
              Subir Archivo
            </Typography>
          </ButtonAddFile>
        )}
      </Layout.Button>
      <Layout.Panel loading={loading}>
        <Grid container spacing={3}>
          <Grid item container xs={12}>
            <Box
              width="100%"
              display={"flex"}
              alignItems="flex-start"
              justifyContent={"space-between"}
              gap="4px"
            >
              {!isRoleToHideData && Boolean(existFiles) ? (
                <Autocomplete
                  value={userSelected}
                  inputValue={userSelectedLabel}
                  onChange={(_, data: any) => setUserSelected(data)}
                  sx={{ width: "250px" }}
                  onInputChange={(_, value) => setUserSelectedLabel(value)}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  options={usersLiked}
                  getOptionLabel={(i) => i.name}
                  renderInput={({
                    InputLabelProps,
                    InputProps,
                    inputProps,
                    ...rest
                  }) => (
                    <TextInput
                      {...rest}
                      InputProps={{
                        ...InputProps,
                        style: { height: 48, paddingTop: "6px" },
                      }}
                      inputProps={inputProps}
                      InputLabelProps={{
                        ...InputLabelProps,
                        style: { top: "-3.5px" },
                      }}
                      label="Filtrar por"
                    />
                  )}
                />
              ) : (
                <Box />
              )}

              {!isRoleToHideData && isMobile && (
                <ButtonAddFile
                  id={id}
                  xs={mainStyles.actionsButtons}
                  setReload={() => setReload(!reload)}
                  users={usersLiked}
                  isMobile={isMobile}
                  icon={<UploadFile />}
                />
              )}
            </Box>
          </Grid>
          <Grid
            item
            container
            spacing={3}
            justifyContent={{ xs: "center", sm: "left" }}
          >
            {existFiles ? (
              filterFiles(Files, userSelected).map((file, index) => (
                <FileCard
                  isRoleToDelete={isRoleToDelete}
                  isRoleToDownload={isRoleToDownload}
                  onDelete={handleDeleteFile}
                  fileObject={file}
                  key={index}
                />
              ))
            ) : (
              <Box
                display={"flex"}
                justifyContent="center"
                alignItems={"center"}
                width={"100%"}
                minHeight="200px"
              >
                <Typography component={"h4"} variant="h4" mt={"20px"}>
                  No hay archivos disponibles
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Layout.Panel>
    </Layout>
  );
};

export default AttachedFiles;
