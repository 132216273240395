import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { ICreateStartup } from "../components/Toolbars/UserToolbar/CrudUsers/schemas";
import { getToken } from "../config";
import { EStatusReleasedOpportunity } from "../constants";
import { IClientsResponse } from "../models/Response";
import { EFileTypes } from "../pages/DetailsStartup/components/TabList/Tabs/AttachedFiles/validations";
import {
  EFileTypesMember,
  EFileTypesMemberAdmin,
} from "../pages/ProfileMember/components/TabList/Tabs/MyDocuments/validations";

const getStartupById = (id: string): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/startups/" + id,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

const getStartupDetailEvaluationsById = (
  id: string,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/startups/evaluations/" + id,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

const updateStartupById = (
  id: string,
  data: any,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/startups/" + id,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

const updateStartupDetailEvaluationsById = (
  id: string,
  data: any,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/startups/evaluations/" + id,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

const updateStartupMainCardById = (
  id: string,
  data: any,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/startups/update-main-card/" + id,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

const getStartupMainCardById = (id: string): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/startups/mainCard/" + id,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const getFinancialInfo = (id: string): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/startups/financialInfo/` + id,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};
export const updateFinancialInfo = (
  id: string,
  data: any,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/startups/financialInfo/` + id,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  return axios(config);
};

function getAttachedFilesStartup(
  id: string,
  userId: string,
): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/startups/attachedFiles/${id}`,
    method: "GET",
    data: {
      user: userId,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

function deleteFileStartup(
  startupId: string,
  fileId: string,
  fileType: EFileTypes | EFileTypesMember | EFileTypesMemberAdmin,
): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/startups/remove-file/`,
    method: "DELETE",
    data: {
      startupId,
      fileId,
      type: fileType,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
function addFileStartup(data: any): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/startups/upload-file/`,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
const downloadFileStartup = (id?: string): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/files/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const getInvestmentRoundInfo = (id: string): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/startups/investRound/` + id,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getReportsInfo = (id: string): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/startups/reports/` + id,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const postReportFile = (data: any): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/startups/upload-file`,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const postPeriodicity = (data: any, id: any): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/startups/periodicity/` + id,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const getUpdateDetailAmaAndExpirationDate = (
  id: string,
  data: any,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/startups/invest-round/" + id,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

const getDashboardStartup = (): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/dashboard/startup",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export function getStartupsByStatus(
  status: EStatusReleasedOpportunity,
): Promise<any> {
  const token = getToken();
  const url = `/startups?status=${status}`;

  const config: AxiosRequestConfig = {
    url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

const updateUsersInvesting = (
  data: any[],
  id: any,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/startups/usersInvesting/` + id,
    method: "POST",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return axios(config);
};

export function createFollowOn(
  data: ICreateStartup,
  id: any,
): Promise<AxiosResponse<IClientsResponse>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/startups/create-follow-on/" + id,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export {
  postPeriodicity,
  getStartupById,
  updateStartupById,
  getStartupDetailEvaluationsById,
  updateStartupDetailEvaluationsById,
  getFinancialInfo,
  getInvestmentRoundInfo as getInfoInvestmentRound,
  getReportsInfo,
  getStartupMainCardById,
  downloadFileStartup,
  updateStartupMainCardById,
  postReportFile,
  getAttachedFilesStartup,
  addFileStartup,
  deleteFileStartup,
  getUpdateDetailAmaAndExpirationDate,
  getDashboardStartup,
  updateUsersInvesting,
};
