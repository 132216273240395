import { ReactNode } from "react";
import * as yup from "yup";

import { IFileObject } from "../../../../components/CustomInputFile/CustomInputFile";
import { CAMPO_REQUERIDO } from "../../../../utils/text/inputs";
import {
  LINKEDIN_URL_VALIDA,
  URL_VALIDA_FORM,
} from "../../../../utils/text/resetForm";
import { VALIDATE_WEB_REGEX } from "../../../DetailsOpportunity/components/MainCard/EditMode/schema";
import { VALIDATE_LINKEDIN_PROFILE_REGEX } from "../../../MemberFaceliftOnboarding/FormRecapcha/schema";

export interface IMainCardProps {
  child?: ReactNode;
  id: string;
}

export interface IModalValues {
  monthlyBurnRate: string;
  runway: string;
  solvingProblem: string;
  uniqueValueProposal: string;
  pitch: string;
  deck: null | string | IFileObject;
}

export const enum EFollowOnCode {
  FIRSTFO = "FC000",
  SECONDFO = "FC001",
  THIRDFO = "FC002",
}

export type FormType = {
  status: string;
  startupName: string;
  oneliner: string;
  industry: string;
  stage: string;
  companyType: string;
  ownerName: string;
  ownerLastName: string;
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
  country: string;
  howFindAngelHub: string;
  website: string;
  deck: null | string | IFileObject;
  pitch: null | string;
  solvingProblem: string;
  uniqueValueProposal: string;
  logo?: string | IFileObject | null;
  baseStartup?: string | null;
  followOnRound?: string | EFollowOnCode | null;
  founders: Array<any>;
  expirationDate: string;
  usersInterested: any[];
  usersWantToInvest: any[];
  usersNotInterested: any[];
  womanFounding: string;
};

export const startupSchema = yup.object({
  startupName: yup.string().required(CAMPO_REQUERIDO),
  companyType: yup.string().required(CAMPO_REQUERIDO),
  oneliner: yup
    .string()
    .max(450, "Campo máximo de 450 caracteres.")
    .required(CAMPO_REQUERIDO),
  industry: yup.string().required(CAMPO_REQUERIDO),
  stage: yup.string().required(CAMPO_REQUERIDO),
  phoneNumber: yup.string().required(CAMPO_REQUERIDO),
  phoneCountryCode: yup.string(),
  email: yup.string().required(CAMPO_REQUERIDO),
  website: yup.string().required(CAMPO_REQUERIDO).matches(VALIDATE_WEB_REGEX, {
    message: URL_VALIDA_FORM,
    excludeEmptyString: true,
  }),
  pitch: yup
    .string()
    .matches(VALIDATE_WEB_REGEX, { message: URL_VALIDA_FORM })
    .nullable(true)
    .typeError(LINKEDIN_URL_VALIDA),
  solvingProblem: yup.string().max(450, "Campo máximo de 450 caracteres."),
  uniqueValueProposal: yup.string().max(450, "Campo máximo de 450 caracteres."),
  howFindAngelHub: yup.string(),
  founders: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      role: yup.string(),
      linkedIn: yup.string().matches(VALIDATE_LINKEDIN_PROFILE_REGEX, {
        message: LINKEDIN_URL_VALIDA,
        excludeEmptyString: true,
      }),
    }),
  ),
});
