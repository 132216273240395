import { GET_FO_PROFILE, GET_STARTUP_PROFILE } from "../actions/followOnSwitch";

export interface IFollowOnSwitch {
  profileId: string;
  profile: number;
}
const initialState: IFollowOnSwitch = {
  profileId: "",
  profile: 0,
};

interface IFOProfileAction {
  type: typeof GET_FO_PROFILE;
  payload: IFollowOnSwitch;
}

interface IStartupProfileAction extends Pick<IFOProfileAction, "payload"> {
  type: typeof GET_STARTUP_PROFILE;
}

type Action = IFOProfileAction | IStartupProfileAction;

export const FollowOnSwitchReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case GET_FO_PROFILE:
      return {
        ...state,
        ...action.payload,
      };
    case GET_STARTUP_PROFILE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
