/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

import { changeEdit } from "../../../../../../redux/actions/LoggedUser";
import { useDispatch, useSelector } from "../../../../../../redux/typedHooks";
import { getInfoInvestmentRound } from "../../../../../../services/startup";
import { FormType } from "../../../MainCard/types";

import EditMode from "./EditMode/EditMode";
import { dateAndExpiration } from "./EditMode/schema";
import InvestMode from "./InvestMode/InvestMode";
import { FormTypeInvestRound } from "./types";

interface IProps {
  isInvested?: boolean;
}

const InvestRound: React.FC<IProps> = ({ isInvested }) => {
  const { id } = useParams() as { id: string };
  const dispatch = useDispatch();
  const { profileId, profile } = useSelector(
    (store) => store.followOnSwitchReducer,
  );

  const [isInvestMode, setIsInvestMode] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);

  const mainCardHook = useFormContext<FormType>();
  const hook = useForm<FormTypeInvestRound>({
    mode: "all",
    resolver: yupResolver(dateAndExpiration),
    defaultValues: {
      requiredInvestment: 0,
      expirationDate: null,
      dateAma: null,
      moic: 0,
    },
  });

  useEffect(() => {
    dispatch(changeEdit(!isInvestMode));
  }, [isInvestMode]);

  useEffect(() => {
    const fetchInvestmentInfo = async () => {
      setIsLoading(true);
      const x = await getInfoInvestmentRound(profile === 1 ? profileId : id);
      const finalData = x.data.payload.info;
      hook.setValue("expirationDate", finalData.expirationDate);
      hook.setValue("requiredInvestment", finalData.requiredInvestment);
      hook.setValue("dateAma", finalData.dateAma);
      hook.setValue("moic", finalData.performance);
      mainCardHook.setValue("usersInterested", finalData.usersInterested);
      mainCardHook.setValue("usersWantToInvest", finalData.usersWantToInvest);
      mainCardHook.setValue("usersNotInterested", finalData.usersNotInterested);
      setIsLoading(false);
    };
    fetchInvestmentInfo();
  }, [hook, id, reload, profileId, profile]);

  return (
    <>
      {isInvestMode ? (
        <InvestMode
          isInvestMode={isInvestMode}
          changeInvestMode={setIsInvestMode}
          loading={isLoading}
          hook={hook}
          reload={reload}
          setReload={setReload}
          isInvested={isInvested}
        />
      ) : (
        <EditMode
          id={id}
          hook={hook}
          loading={isLoading}
          changeInvestMode={setIsInvestMode}
          handleReload={() => setReload(!reload)}
        />
      )}
    </>
  );
};

export default InvestRound;
