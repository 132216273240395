/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { useSelector } from "../../../../redux/typedHooks";
import { getStartupMainCardById } from "../../../../services/startup";
import { ERolesCode } from "../../../routes/role-catalog";

import CompleteModal from "./CompleteModal/CompleteModal";
import { EditMode } from "./EditMode/EditMode";
import { ReadMode } from "./ReadMode/ReadMode";
import { styles } from "./styles";
import { FormType, IMainCardProps, IModalValues } from "./types";

const MainCard: FC<IMainCardProps> = ({ id }) => {
  const [enableEditMode, setEnableEditMode] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [reload, setReload] = useState(true);
  const [loading, setLoading] = useState(true);

  const { profileId } = useSelector((store) => store.followOnSwitchReducer);

  const { pathname } = useLocation();
  const isProfile = pathname.includes("startup-profile");

  const [modalValues, setModalValues] = useState<IModalValues>({
    monthlyBurnRate: "",
    runway: "",
    solvingProblem: "",
    uniqueValueProposal: "",
    deck: null,
    pitch: "",
  });

  const hook = useFormContext<FormType>();

  const { loggedUser } = useSelector((store) => store.loggedUser);

  function handleDisableEdit() {
    setReload(!reload);
    setEnableEditMode(!enableEditMode);
    hook.reset();
  }

  function handleGetStartup() {
    if (!id) return;
    setLoading(true);
    getStartupMainCardById(id)
      .then((resp: any) => {
        const data = resp.data.payload.info;
        hook.setValue("startupName", data.startupName);
        hook.setValue("companyType", data.companyType);
        hook.setValue("oneliner", data.oneliner);
        hook.setValue("industry", data.industry);
        hook.setValue("stage", data.stage);
        hook.setValue("status", data.status);
        hook.setValue("howFindAngelHub", data.howFindAngelHub);
        hook.setValue("phoneNumber", data.phoneNumber.toString());
        hook.setValue("phoneCountryCode", data.phoneCountryCode.toString());
        hook.setValue("ownerName", data.ownerName);
        hook.setValue("ownerLastName", data.ownerLastName);
        hook.setValue("website", data.website);
        hook.setValue("country", data.country);
        hook.setValue("solvingProblem", data.solvingProblem);
        hook.setValue("uniqueValueProposal", data.uniqueValueProposal);
        hook.setValue("email", data.email);
        hook.setValue("womanFounding", data.womanFounding);
        hook.setValue("founders", data?.founders);
        hook.setValue("logo", data.logo);
        if (data.deck) hook.setValue("deck", data.deck);
        hook.setValue("expirationDate", data.expirationDate);
        hook.setValue("pitch", data.pitch);
        hook.setValue("usersInterested", data.usersInterested);
        hook.setValue("usersWantToInvest", data.usersWantToInvest);
        hook.setValue("usersNotInterested", data.usersNotInterested);
        hook.setValue("baseStartup", data.baseStartup);
        hook.setValue("followOnRound", data.followOnRound);

        const isIncomplete =
          !data.monthlyBurnRate ||
          !data.runway ||
          !data.solvingProblem ||
          !data.uniqueValueProposal ||
          !data.deck ||
          !data.pitch;

        if (isIncomplete) {
          setModalValues({
            monthlyBurnRate: data.monthlyBurnRate,
            runway: data.runway,
            solvingProblem: data.solvingProblem,
            uniqueValueProposal: data.uniqueValueProposal,
            deck: data.deck,
            pitch: data.pitch,
          });
        }

        setShowAlert(isIncomplete);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    handleGetStartup();
  }, [id, reload]);

  return (
    <Paper square sx={styles.container}>
      {loading && (
        <Box
          height="548px"
          width={"100%"}
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
        >
          <CircularProgress />
        </Box>
      )}
      {showAlert &&
        !loading &&
        id === loggedUser.user?.startup?._id &&
        loggedUser.user.role.code === ERolesCode.STARTUP &&
        isProfile &&
        !enableEditMode && (
          <Alert
            variant="outlined"
            severity="info"
            sx={{ marginBottom: "2rem", marginRight: "1rem", width: "100%" }}
          >
            <Typography>
              Hay información que necesitas completar en tu perfil
            </Typography>
            <CompleteModal
              id={id}
              modalValues={modalValues}
              setReload={() => setReload(!reload)}
            />
          </Alert>
        )}
      {enableEditMode && !loading && (
        <Grid container spacing={2}>
          <EditMode
            hook={hook}
            id={id}
            changeMode={() => setEnableEditMode(!enableEditMode)}
            setReload={() => setReload(!reload)}
            cancelMode={() => handleDisableEdit()}
          />
        </Grid>
      )}
      {!enableEditMode && !loading && (
        <ReadMode
          id={loggedUser.user?.startup?._id === profileId ? profileId : id}
          hook={hook}
          changeMode={() => setEnableEditMode(!enableEditMode)}
        />
      )}
    </Paper>
  );
};

export default MainCard;
