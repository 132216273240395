import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DownloadIcon from "@mui/icons-material/Download";
import PieChartIcon from "@mui/icons-material/PieChart";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useSelector } from "../../../../../../redux/typedHooks";
import { getReportsInfo } from "../../../../../../services/startup";
import { handleDownloadReports } from "../../../../../../utils/helpers/downloadFile";
import { IFile } from "../../../../../../utils/types/common";
import Layout from "../../Layout/Layout";
import { styles as mainStyles } from "../../styles";
import { RESPONSIVE_BUTTON } from "../../types";

import { ButtonUploadReport } from "./ButtonUploadReport/ButtonUploadReport";
import { Modal } from "./ModalReports/Modal";
import { PeriodicityModal } from "./periodicityModal/PeriodicityModal";
import { styles } from "./styles";
import { isValidToUpload } from "./validations";

export interface IOpportunityId {
  id: string;
}

const Reports = () => {
  const breakpoint = useMediaQuery(RESPONSIVE_BUTTON);
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const { profileId, profile } = useSelector(
    (store) => store.followOnSwitchReducer,
  );
  const { id } = useParams() as IOpportunityId;
  const [reload, setReload] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  const [Files, setFiles] = useState<IFile[]>([]);
  const [openModalPeriodicity, setopenModalPeriodicity] = useState(false);
  const [periodicity, setPeriodicidad] = useState<boolean>(false);
  const [periodicityValue, setPeriodicityValue] = useState<boolean>(false);
  const isValidRole = isValidToUpload.includes(user.role.code);

  useEffect(() => {
    const fetchReportsInfo = async () => {
      const reportsInfo = await getReportsInfo(profile === 1 ? profileId : id);
      const reports = reportsInfo.data.payload.info;
      const periodicity = reportsInfo.data.payload.periodicity;
      setPeriodicityValue(periodicity);
      setPeriodicidad(periodicity === null ? false : true);
      setFiles(reports);
    };
    fetchReportsInfo().then(() => {
      setLoading(false);
    });
  }, [id, reload, profileId, profile]);

  return (
    <Layout>
      <Layout.Button>
        {isValidRole && !breakpoint && (
          <ButtonUploadReport
            setReload={setReload}
            reload={reload}
            periodicity={periodicity}
            openPeriodicity={() =>
              setopenModalPeriodicity(!openModalPeriodicity)
            }
          />
        )}
      </Layout.Button>
      <Layout.Panel loading={loading}>
        <Grid container spacing={3}>
          {openModalPeriodicity && (
            <PeriodicityModal
              openModal={setopenModalPeriodicity}
              setReload={setReload}
              reload={reload}
            />
          )}
          {isValidRole && breakpoint && (
            <Grid item xs={12} sx={{ ...mainStyles.buttonsContainer }}>
              <ButtonUploadReport
                setReload={setReload}
                reload={reload}
                periodicity={periodicity}
                openPeriodicity={() =>
                  setopenModalPeriodicity(!openModalPeriodicity)
                }
                isMobile
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sx={{
              ...mainStyles.Info,
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <CalendarMonthIcon sx={{ ...mainStyles.Icons }} />
            <Box
              sx={{
                ...styles.periodTitle,
                display: "flex",
                alignItems: "flex-end",
                gap: 1,
              }}
            >
              <Typography sx={{ ...mainStyles.title, ...styles.reportTitle }}>
                Periodicidad de reportes:
              </Typography>
              <Box
                sx={{ ...mainStyles.title, ...styles.monthsResponsive }}
                display={"flex"}
                gap={1}
              >
                <strong style={{ fontSize: "24px" }}>
                  {periodicityValue ? (
                    periodicityValue
                  ) : (
                    <Typography sx={{ fontSize: "17px" }}>
                      Sin periodo definido
                    </Typography>
                  )}
                </strong>
                <span>{periodicityValue ? "meses" : ""}</span>
              </Box>
            </Box>
          </Grid>
          {Files.length > 0 && (
            <Grid item xs={12} sx={{ ...mainStyles.Info }}>
              <PieChartIcon sx={{ ...mainStyles.Icons }} />
              <Box
                sx={{
                  ...styles.reportContainer,
                  maxHeight: "230px",
                  overflow: "hidden",
                }}
              >
                <Typography sx={{ ...mainStyles.title, ...styles.reportTitle }}>
                  Reportes
                </Typography>

                {Files.map((item: any, index: number) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        ...styles.reportContainer.subContainer,
                      }}
                    >
                      <Box
                        sx={{
                          ...styles.alignElementsBox,
                          ...styles.borderResponsive,
                        }}
                      >
                        <Typography
                          sx={{ textTransform: "capitalize" }}
                        >{`${dayjs(item.date).format(
                          "DD MMMM YYYY",
                        )} • Reporte ${dayjs(item.date).format(
                          "MMMM",
                        )}`}</Typography>
                        <DownloadIcon
                          onClick={() => handleDownloadReports(item)}
                          sx={{ ...styles.buttonDownload, cursor: "pointer" }}
                        />
                      </Box>
                    </Box>
                  );
                })}
                {openModal && <Modal openModal={setOpenModal} Files={Files} />}
              </Box>
            </Grid>
          )}

          {Files.length > 0 ? (
            <Grid xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  disabled={Files.length < 6}
                  sx={{ ...styles.seeAll }}
                  onClick={() => setOpenModal(!openModal)}
                >
                  VER TODOS
                </Button>
              </Box>
            </Grid>
          ) : (
            <Box
              display={"flex"}
              justifyContent="center"
              alignItems={"center"}
              width={"100%"}
              minHeight="200px"
            >
              <Typography component={"h4"} variant="h4" mt={"20px"}>
                No hay archivos disponibles
              </Typography>
            </Box>
          )}
        </Grid>
      </Layout.Panel>
    </Layout>
  );
};

export default Reports;
