import * as yup from "yup";

import { VALIDATE_LINKEDIN_PROFILE_REGEX } from "../../../../pages/MemberFaceliftOnboarding/FormRecapcha/schema";
import {
  CAMPO_REQUERIDO,
  CORREO_NO_VALIDO,
} from "../../../../utils/text/inputs";
import {
  LINKEDIN_URL_VALIDA,
  URL_VALIDA,
  URL_VALIDA_FORM,
} from "../../../../utils/text/resetForm";
import { IFounderData } from "../../../DetailsStartup/Forms/schema";

export interface ICreateAdmin {
  firstName: string;
  lastName: string;
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
  position: string;
}
export interface ICreateAnalyst {
  firstName: string;
  lastName: string;
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
  position: string;
  target: string;
  calendlyEvent: string;
}
export interface ICreateMember {
  email: string;
  firstName: string;
  lastName: string;
  phoneCountryCode: string;
  phoneNumber: string;
  nationalityCountry: string;
  residenceCountry: string;
  residenceCity: string;
  linkedInProfile?: string;
}
export interface ICreateStartup {
  email: string;
  firstName: string;
  lastName: string;
  phoneCountryCode: string;
  phoneNumber: string;
  nameStartup: string;
  website: string;
  residenceCountry: string;
  companyType: string;
  oneliner: string;
  industry: string;
  stage: string;
  requiredInvestment: string;
  preMoney: string;
  founders?: IFounderData[];
}

export const createMemberSchema = yup.object({
  firstName: yup.string().required(CAMPO_REQUERIDO),
  lastName: yup.string().required(CAMPO_REQUERIDO),
  email: yup.string().email(CORREO_NO_VALIDO).required(CAMPO_REQUERIDO),
  phoneNumber: yup.string().required(CAMPO_REQUERIDO),
  nationalityCountry: yup.string().required(CAMPO_REQUERIDO),
  residenceCountry: yup.string().required(CAMPO_REQUERIDO),
  residenceCity: yup.string().required(CAMPO_REQUERIDO),
  linkedInProfile: yup
    .string()
    .test("is-correct-linkedin-url", LINKEDIN_URL_VALIDA, (value) => {
      if (value === "" || value === undefined) {
        return true;
      } else {
        return value.match(VALIDATE_LINKEDIN_PROFILE_REGEX) !== null;
      }
    }),
  membershipType: yup.string().required(CAMPO_REQUERIDO),
});

export const createStartupSchema = yup.object({
  email: yup.string().email(CORREO_NO_VALIDO).required(CAMPO_REQUERIDO),
  firstName: yup.string().required(CAMPO_REQUERIDO),
  lastName: yup.string().required(CAMPO_REQUERIDO),
  phoneCountryCode: yup.string().required(CAMPO_REQUERIDO),
  phoneNumber: yup.string().required(CAMPO_REQUERIDO),
  nameStartup: yup.string().required(CAMPO_REQUERIDO),
  website: yup.string().url(URL_VALIDA_FORM).required(CAMPO_REQUERIDO),
  country: yup.string().required(CAMPO_REQUERIDO),
  companyType: yup.string().required(CAMPO_REQUERIDO),
  oneliner: yup
    .string()
    .max(450, "Campo máximo de 450 caracteres.")
    .required(CAMPO_REQUERIDO),
  industry: yup.string().required(CAMPO_REQUERIDO),
  stage: yup.string().required(CAMPO_REQUERIDO),
  requiredInvestment: yup.string().required(CAMPO_REQUERIDO),
  preMoney: yup.string().required(CAMPO_REQUERIDO),
});

export const createAnalystSchema = yup.object({
  firstName: yup.string().required(CAMPO_REQUERIDO),
  lastName: yup.string().required(CAMPO_REQUERIDO),
  email: yup.string().email(CORREO_NO_VALIDO).required(CAMPO_REQUERIDO),
  phoneCountryCode: yup.string().required(CAMPO_REQUERIDO),
  phoneNumber: yup.string().required(CAMPO_REQUERIDO),
  position: yup.string().required(CAMPO_REQUERIDO),
  target: yup.string().required(CAMPO_REQUERIDO),
  calendlyEvent: yup.string().url(URL_VALIDA).required(CAMPO_REQUERIDO),
  linkedInProfile: yup
    .string()
    .test("is-correct-linkedin-url", LINKEDIN_URL_VALIDA, (value) => {
      if (value === "" || value === undefined) {
        return true;
      } else {
        return value.match(VALIDATE_LINKEDIN_PROFILE_REGEX) !== null;
      }
    }),
});

export const createAdminSchema = yup.object({
  firstName: yup.string().required(CAMPO_REQUERIDO),
  lastName: yup.string().required(CAMPO_REQUERIDO),
  email: yup.string().email(CORREO_NO_VALIDO).required(CAMPO_REQUERIDO),
  phoneCountryCode: yup.string().required(CAMPO_REQUERIDO),
  phoneNumber: yup.string().required(CAMPO_REQUERIDO),
  position: yup.string().required(CAMPO_REQUERIDO),
  linkedInProfile: yup
    .string()
    .test("is-correct-linkedin-url", LINKEDIN_URL_VALIDA, (value) => {
      if (value === "" || value === undefined) {
        return true;
      } else {
        return value.match(VALIDATE_LINKEDIN_PROFILE_REGEX) !== null;
      }
    }),
});

export const createPartnerSchema = yup.object({
  firstName: yup.string().required(CAMPO_REQUERIDO),
  lastName: yup.string().required(CAMPO_REQUERIDO),
  email: yup.string().email(CORREO_NO_VALIDO).required(CAMPO_REQUERIDO),
  phoneCountryCode: yup.string().required(CAMPO_REQUERIDO),
  phoneNumber: yup.string().required(CAMPO_REQUERIDO),
  position: yup.string().required(CAMPO_REQUERIDO),
  calendlyEvent: yup.string().url(URL_VALIDA).required(CAMPO_REQUERIDO),
  linkedInProfile: yup
    .string()
    .test("is-correct-linkedin-url", LINKEDIN_URL_VALIDA, (value) => {
      if (value === "" || value === undefined) {
        return true;
      } else {
        return value.match(VALIDATE_LINKEDIN_PROFILE_REGEX) !== null;
      }
    }),
});
