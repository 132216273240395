import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import { getFOProfile } from "../../redux/actions/followOnSwitch";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import MainCard from "../DetailsStartup/components/MainCard/MainCard";
import {
  FormType,
  startupSchema,
} from "../DetailsStartup/components/MainCard/types";
import { ERolesCode } from "../routes/role-catalog";

import styles from "./styles";

const DetailsStartup: React.FC = () => {
  const { id } = useParams() as any;
  const dispatch = useDispatch();

  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const { profileId, profile } = useSelector(
    (store) => store.followOnSwitchReducer,
  );
  useEffect(() => {
    if (profile === 1) {
      dispatch(
        getFOProfile({
          profileId: user?.followOn[0]._id,
          profile: 1,
        }),
      );
    } else {
      dispatch(
        getFOProfile({
          profileId: user.startup._id,
          profile: 0,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, profileId]);

  const startupUserId = user?.startup?._id;
  const role = user.role.code;

  const isStartupValidToShow =
    startupUserId === id && role === ERolesCode.STARTUP;

  const hook = useForm<FormType>({
    resolver: yupResolver(startupSchema),
    mode: "all",
    defaultValues: {
      status: "",
      startupName: "",
      oneliner: "",
      industry: "",
      stage: "",
      companyType: "",
      ownerName: "",
      ownerLastName: "",
      email: "",
      phoneCountryCode: "",
      phoneNumber: "",
      country: "",
      howFindAngelHub: "",
      website: "",
      deck: null,
      pitch: null,
      solvingProblem: "",
      uniqueValueProposal: "",
      founders: [
        {
          name: "",
          role: "",
          linkedIn: "",
        },
        {
          name: "",
          role: "",
          linkedIn: "",
        },
        {
          name: "",
          role: "",
          linkedIn: "",
        },
      ],
      usersInterested: [],
      usersWantToInvest: [],
      usersNotInterested: [],
    },
  });

  return (
    <FormProvider {...hook}>
      <PageTitle title="Detalle de Startup" />
      <MainLayout sx={{ flexWrap: "wrap", display: "wrap" }}>
        <Box sx={styles.container}>
          <Box sx={styles.mainCard}>
            <MainCard id={isStartupValidToShow ? profileId : id} />
          </Box>
        </Box>
      </MainLayout>
    </FormProvider>
  );
};

export default DetailsStartup;
