import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import { EStatusReleasedOpportunity } from "../../constants";
import { getFOProfile } from "../../redux/actions/followOnSwitch";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import { ERolesCode } from "../routes/role-catalog";

import MainCard from "./components/MainCard/MainCard";
import { FormType, startupSchema } from "./components/MainCard/types";
import { ParamTabProvider } from "./components/TabList/Context/ContextParamTabs";
import TabList from "./components/TabList/TabList";
import styles from "./styles";
import { VALID_ROLES_TO_SHOW_TABS } from "./validations";

const DetailsStartup: React.FC = () => {
  const { id } = useParams() as any;
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const { profileId, profile } = useSelector(
    (store) => store.followOnSwitchReducer,
  );

  const isAngelGroup = [
    ERolesCode.ADMINISTRATOR,
    ERolesCode.SUPER_ADMIN,
    ERolesCode.PARTNER,
    ERolesCode.ANALYST,
    ERolesCode.MEMBER,
  ].includes(user.role.code);

  useEffect(() => {
    if (isAngelGroup) return;
    if (profile === 1) {
      dispatch(
        getFOProfile({
          profileId: user?.followOn[0]._id,
          profile: 1,
        }),
      );
    } else {
      dispatch(
        getFOProfile({
          profileId: user.startup._id,
          profile: 0,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, profileId]);
  const startupUserId = user?.startup?._id;
  const role = user.role.code;

  const isStartupValidToShow =
    startupUserId === id && role === ERolesCode.STARTUP;
  const hook = useForm<FormType>({
    resolver: yupResolver(startupSchema),
    mode: "all",
    defaultValues: {
      status: "",
      startupName: "",
      oneliner: "",
      industry: "",
      stage: "",
      companyType: "",
      ownerName: "",
      ownerLastName: "",
      email: "",
      phoneCountryCode: "",
      phoneNumber: "",
      country: "",
      howFindAngelHub: "",
      website: "",
      deck: null,
      pitch: null,
      solvingProblem: "",
      uniqueValueProposal: "",
      founders: [
        {
          name: "",
          role: "",
          linkedIn: "",
        },
        {
          name: "",
          role: "",
          linkedIn: "",
        },
        {
          name: "",
          role: "",
          linkedIn: "",
        },
      ],
      usersInterested: [],
      usersWantToInvest: [],
      usersNotInterested: [],
    },
  });

  const validRole = VALID_ROLES_TO_SHOW_TABS.includes(role);
  const isInvested =
    hook.getValues("status") === EStatusReleasedOpportunity.INVESTED;

  return (
    <FormProvider {...hook}>
      <ParamTabProvider>
        <PageTitle title="Detalle de Startup" />
        <MainLayout>
          <Box sx={styles.container}>
            <Box>
              <MainCard id={isStartupValidToShow ? profileId : id} />
            </Box>
            {(isStartupValidToShow || validRole) && (
              <Box sx={styles.tab}>
                <TabList
                  id={isStartupValidToShow ? profileId : id}
                  isInvested={isInvested}
                />
              </Box>
            )}
          </Box>
        </MainLayout>
      </ParamTabProvider>
    </FormProvider>
  );
};

export default DetailsStartup;
