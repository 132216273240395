import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Controller, useForm, useFormContext } from "react-hook-form";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { useHistory, useParams } from "react-router-dom";

import { useLoading } from "../../../../hooks/useLoading";
import { FormType } from "../../../../pages/DetailsStartup/components/MainCard/types";
import {
  COMPANY_TYPE_OPTIONS,
  INDUSTRY_OPTIONS,
  STAGE_OPTIONS,
} from "../../../../pages/StartupRegister/constants";
import { reloadReducerUser } from "../../../../redux/actions/admin";
import { openModal, PayloadOpenModal } from "../../../../redux/actions/modal";
import { useDispatch } from "../../../../redux/typedHooks";
import { createFollowOn } from "../../../../services/startup";
import countries from "../../../../utils/countries.json";
import { displayToast } from "../../../../utils/helpers/displayToast";
import { TextInput } from "../../../Inputs";

import { createStartupSchema, ICreateStartup } from "./schemas";
import { stylesModal } from "./stylesModal";

interface IProps {
  handleOpenModal: boolean;
  handleCloseModal: () => void;
}

type FollowOnFormType = {
  email: string;
  firstName: string;
  lastName: string;
  phoneCountryCode: string;
  phoneNumber: string;
  nameStartup: string;
  website: string;
  country: string;
  companyType: string;
  oneliner: string;
  industry: string;
  stage: string;
  requiredInvestment: number;
  preMoney: number;
};

export default function CreateFollowOn({
  handleOpenModal,
  handleCloseModal,
}: IProps) {
  const isMobile = useMediaQuery("(max-width:700px)");
  const { id } = useParams() as { id: string };
  const hook = useFormContext<FormType>();

  const history = useHistory();
  const dispatch = useDispatch();
  const { handleClose, handleOpen } = useLoading();

  const followOnHook = useForm<FollowOnFormType>({
    resolver: yupResolver(createStartupSchema),
    mode: "all",
    defaultValues: {
      email: hook.getValues("email"),
      firstName: hook.getValues("ownerName"),
      lastName: hook.getValues("ownerLastName"),
      phoneCountryCode: hook.getValues("phoneCountryCode"),
      phoneNumber:
        hook.getValues("phoneCountryCode") + hook.getValues("phoneNumber"),
      nameStartup: hook.getValues("startupName"),
      website: hook.getValues("website"),
      companyType: hook.getValues("companyType"),
      oneliner: hook.getValues("oneliner"),
      industry: hook.getValues("industry"),
      stage:
        hook.getValues("stage").charAt(0).toUpperCase() +
        hook.getValues("stage").slice(1).toLowerCase(),
      country: hook.getValues("country"),
      requiredInvestment: 0,
      preMoney: 0,
    },
  });

  function handleSuccessModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Correcto",
      description: `Follow On creado satisfactoriamente`,
      action: () => history.push("/oportunidades-aceptadas-admin"),
      hideSecondaryButton: true,
      primaryButtonText: "Aceptar",
    };
    dispatch(openModal(modalConfig));
  }

  const handleCancel = () => {
    handleCloseModal();
    followOnHook.reset();
  };

  async function onSubmit(values: any): Promise<void> {
    try {
      handleOpen();
      const finalValues: ICreateStartup = {
        ...values,
        phoneCountryCode: values.phoneCountryCode,
        phoneNumber: values.phoneNumber.slice(values.phoneCountryCode.length),
        founders: hook.getValues("founders"),
      };
      await createFollowOn(finalValues, id);
      followOnHook.reset();
      dispatch(reloadReducerUser());
      handleSuccessModal();
      handleCloseModal();
    } catch (err: any) {
      const message = err?.data?.message;
      displayToast("error", message);

      console.log(message);
    } finally {
      handleClose();
    }
  }

  return (
    <Modal
      open={handleOpenModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleCancel}
      style={{ overflow: "scroll" }}
    >
      <Grid
        container
        sx={{
          ...stylesModal.Modal,
          ...stylesModal.ModalCustomHeightForStartup,
          maxWidth: "1018px",
        }}
        justifyContent={{ xs: "center", sm: "left" }}
        spacing={2}
      >
        <Grid item xs={12} sm={12}>
          <Typography sx={stylesModal.ModalTitle}>Crear Follow On</Typography>
          <br />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={followOnHook.control}
            name="nameStartup"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Nombre de Startup"
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={followOnHook.control}
            name="firstName"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Nombres"
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={followOnHook.control}
            name="lastName"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Apellidos"
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={followOnHook.control}
            name="email"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Correo"
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={followOnHook.control}
            name="phoneNumber"
            render={({ field, fieldState }) => (
              <>
                <PhoneInput
                  country="mx"
                  value={field.value}
                  onChange={(number, countryData: CountryData) => {
                    followOnHook.setValue(
                      "phoneCountryCode",
                      countryData.dialCode,
                    );
                    followOnHook.setValue("phoneNumber", number);
                    field.onChange(number);
                  }}
                  buttonStyle={
                    fieldState.error ? { border: "2px solid #f44336" } : {}
                  }
                  inputStyle={
                    fieldState.error
                      ? {
                          border: "2px solid #f44336",
                          height: "48px",
                          width: "100%",
                        }
                      : { height: "48px", width: "100%" }
                  }
                />
                {Boolean(fieldState.error) && (
                  <FormHelperText error={Boolean(fieldState.error?.message)}>
                    {fieldState.error?.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={followOnHook.control}
            name="country"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="País"
                required
                select
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              >
                {countries.map((option, index) => (
                  <MenuItem key={index} value={option.name_es}>
                    {option.name_es}
                  </MenuItem>
                ))}
              </TextInput>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={followOnHook.control}
            name="website"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Sitio web"
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            control={followOnHook.control}
            name="oneliner"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                sx={isMobile ? { width: "100%" } : { width: "66%" }}
                label="Oneliner"
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
                multiline
                rows={3}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={followOnHook.control}
            name="industry"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Industria"
                required
                select
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              >
                {INDUSTRY_OPTIONS.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextInput>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={followOnHook.control}
            name="stage"
            render={({ field, fieldState }) => {
              const normalizedStage = STAGE_OPTIONS.find(
                (option) => option.toUpperCase() === field.value.toUpperCase(),
              );
              return (
                <TextInput
                  {...field}
                  label="Etapa"
                  value={normalizedStage || ""}
                  required
                  select
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                  onChange={(event) => field.onChange(event.target.value)}
                >
                  {STAGE_OPTIONS.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextInput>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={followOnHook.control}
            name="companyType"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Tipo de empresa"
                required
                select
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              >
                {COMPANY_TYPE_OPTIONS.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextInput>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={followOnHook.control}
            name="requiredInvestment"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                type="numeric"
                label="Inversion Requerida (USD)"
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={followOnHook.control}
            name="preMoney"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                type="numeric"
                label="Valuación de Post Money (USD)"
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent={{ xs: "center", sm: "right" }}
        >
          <Button onClick={handleCancel} variant="outlined">
            Cancelar
          </Button>
          <LoadingButton
            onClick={followOnHook.handleSubmit(onSubmit)}
            variant="contained"
            sx={{ marginLeft: "10px" }}
          >
            Crear
          </LoadingButton>
        </Grid>
      </Grid>
    </Modal>
  );
}
